// ** Initial State
const initialState = {
  revwDvcdList: [],
  reviewList: [],
  total: 1,
  params: {}
}

const reviews = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_REVIEW_LIST':
      return { 
        ...state, 
        reviewList: action.data.reviewList,
        revwDvcdList: action.data.revwDvcdList
      }
    case 'INSERT_REVIEW':
    case 'MODIFY_REVIEW':
    case 'DELETE_REVIEW':
      return {
        ...state,
        reviewList: action.data
      }
    default:
      return { ...state }
  }
}
export default reviews
