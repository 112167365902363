// ** Initial State
const initialState = {
  bzownerContactList: [],
  total: 1,
  params: {}
}

const bzowner = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BZOWNER_CONTACT_LIST':
      return {
        ...state,
        bzownerContactList: action.data.bzownerContactList
      }
    default:
      return { ...state }
  }
}
export default bzowner
