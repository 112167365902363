// ** Initial State
const initialState = {
  custUserList: [],
  joinDvcdList: [],
  selectedCustUserInfo: {},
  total: 1,
  params: {}
}

const custUsers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CUST_USER_LIST':
      return { ...state, 
              custUserList: action.data.custUserList
              }
    case 'GET_JOIN_DVCD_LIST':
      return { ...state, 
              joinDvcdList: action.data.joinDvcdList
              }
    case 'SELECT_CUST_USER_DATA':
      return {
              ...state,
              selectedCustUserInfo: action.data
              }
    default:
      return { ...state }
  }
}
export default custUsers
