// ** Initial State
const initialState = {
  unitPriceList: [],
  unitPriceItemList: [],
  unitPriceTierList: [],
  unitPriceRegionList: [],
  unitPriceRegionCodeList: [],
  regionGroupCodeList: [],
  unitPriceApplyPriceList: [],
  unitPriceTypeList: [],
  clientCompanyList: [],
  selectedUnitPriceItem: {},
  total: 1,
  params: {}
}

const unitprice = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_UNITPRICE':
      return { ...state, 
                  unitPriceList: action.data.unitPriceList,
                  unitPriceTypeList: action.data.codeList,
                  clientCompanyList: action.data.clientCompanyList
      }
    case 'GET_UNITPRICE_DETAIL':
      return { ...state, 
                  unitPriceItemList: action.data.unitPriceItemList,
                  unitPriceTierList: action.data.unitPriceTierList,
                  unitPriceRegionList: action.data.unitPriceRegionList,
                  unitPriceApplyPriceList: action.data.unitPriceApplyPriceList
      }
    case 'GET_UNITPRICE_ITEM':
      return { ...state, 
                  unitPriceItemList: action.data.unitPriceItemList
      }
    case 'INSERT_UNITPRICE_ITEM' : 
    case 'MODIFY_UNITPRICE_ITEM' : 
    case 'DELETE_UNITPRICE_ITEM' :
      return { ...state, 
                  unitPriceItemList: action.data
      }
    case 'GET_UNITPRICE_TIER':
      return { ...state, 
                  unitPriceTierList: action.data.unitPriceTierList
      }
    case 'INSERT_UNITPRICE_TIER' :
    case 'MODIFY_UNITPRICE_TIER' :
    case 'DELETE_UNITPRICE_TIER' :
      return { ...state, 
                  unitPriceTierList: action.data
      }
    case 'GET_UNITPRICE_REGION':
      return { ...state, 
                  unitPriceRegionList: action.data.unitPriceRegionList
      }
    case 'INSERT_UNITPRICE_REGION' :
    case 'MODIFY_UNITPRICE_REGION' :
    case 'DELETE_UNITPRICE_REGION' :
      return { ...state, 
                  unitPriceRegionList: action.data
      }
    case 'GET_UNITPRICE_REGIONCODE':
      return { ...state, 
                  unitPriceRegionCodeList: action.data.unitPriceRegionCodeList,
                  regionGroupCodeList: action.data.regionGroupCodeList
      }
    case 'INSERT_UNITPRICE_REGIONCODE' :
    case 'DELETE_UNITPRICE_REGIONCODE' :
      return { ...state, 
                  unitPriceRegionCodeList: action.data
      }
    case 'GET_UNITPRICE_APPLYPRICE':
      return { ...state, 
                  unitPriceApplyPriceList: action.data.unitPriceApplyPriceList
      }
    case 'INSERT_UNITPRICE_APPLYPRICE' :
    case 'MODIFY_UNITPRICE_APPLYPRICE' :
    case 'DELETE_UNITPRICE_APPLYPRICE' :
      return { ...state, 
                  unitPriceApplyPriceList: action.data
      }
    case 'INSERT_UNITPRICE':
      return {
        ...state,
        data: action.data
        // total: action.totalPages,
        // params: action.params
      }
    case 'SELECT_ITEM_DATA':
      return {
        ...state,
        selectedUnitPriceItem: action.data
      }
    default:
      return { ...state }
  }
}
export default unitprice
