// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import photographers from 'views/photographer/store/reducer'
import cooperations from 'views/cooperation/store/reducer'
import project from 'views/project/store/reducer'
import categories from 'views/category/store/reducer'
import checklists from 'views/checklist/store/reducer'
import unitprices from 'views/unitprice/store/reducer'
import templates from 'views/template/store/reducer'
import clients from 'views/client/store/reducer'
import notifications from 'views/notification/store/reducer'
import tasks from 'views/task/store/reducer'
import accounts from 'views/account/store/reducer'
import spending from 'views/spending/store/reducer'
import manager from 'views/manager/store/reducer'
import portfolios from 'views/market/portfolio/store/reducer'
import quotations from 'views/market/quotation/store/reducer'
import connections from 'views/market/connect/store/reducer'
import reviews from 'views/market/review/store/reducer'
import productCategories from 'views/market/category/store/reducer'
import products from 'views/market/product/store/reducer'
import orderProducts from 'views/market/order/store/reducer'
import custUsers from 'views/market/user/store/reducer'
import statistics from 'views/market/statistics/store/reducer'
import boards from 'views/board/store/reducer'
import shootingRequest from 'views/history/request/store/reducer'
import bzownerContact from 'views/history/bzowner/store/reducer'
import dashboard from 'views/dashboard/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  project,
  photographers,
  cooperations,
  categories,
  checklists,
  unitprices,
  templates,
  clients,
  notifications,
  tasks,
  accounts,
  spending,
  manager,
  portfolios,
  quotations,
  connections,
  reviews,
  productCategories,
  products,
  orderProducts,
  custUsers,
  boards,
  statistics,
  shootingRequest,
  bzownerContact,
  dashboard
})

export default rootReducer
