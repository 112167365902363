// ** Initial State
const initialState = {
  previewProductInfo: {},
  previewProductAttachFileList: [],
  previewProductOptionList: [],
  previewProductOptionItemList: [],
  previewProductBoardList: [],
  productList: [],
  productAttachFileList: [],
  productOptionList: [],
  productOptionItemList: [],
  productCategoryList: [],  
  boardList: [],
  selectedProductOption: {},
  selectedProductOptionItem: {},
  total: 1,
  params: {}
}

const product = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT':
      return {
        ...state,
        previewProductInfo: action.data.productInfo,
        previewProductAttachFileList: action.data.productAttachFileList,
        previewProductOptionList: action.data.productOptionList,
        previewProductOptionItemList: action.data.productOptionItemList,
        previewProductBoardList: action.data.productBoardList
      }
    case 'GET_PRODUCT_CATEGORY':
      return { ...state, 
                  productCategoryList: action.data.productCategoryList
              }
    case 'GET_PRODUCT_LIST':
      return { ...state, 
                  productList: action.data.productList,
                  boardList: action.data.boardList
              }
    case 'GET_PRODUCT_ATTACHFILE':
      return { ...state, 
                  productAttachFileList: action.data.productAttachFileList
              }
    case 'GET_PRODUCT_OPTION':
      return { ...state, 
                  productOptionList: action.data.productOptionList,
                  productAttachFileList: action.data.productAttachFileList
              }
    case 'GET_PRODUCT_OPTION_ITEM':
      return { ...state, 
                  productOptionItemList: action.data.productOptionItemList
              }
    case 'INSERT_PRODUCT':
    case 'MODIFY_PRODUCT':              
    case 'DELETE_PRODUCT':
      return {
        ...state,
        productList: action.data
      }
    case 'SELECT_OPTION_DATA':
      return {
        ...state,
        selectedProductOption: action.data
      }
    case 'SELECT_OPTION_ITEM_DATA':
      return {
        ...state,
        selectedProductOptionItem: action.data
      }  
    default:
      return { ...state }
  }
}
export default product
