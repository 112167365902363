// ** Initial State
const initialState = {
  topCategoryList: [],
  connectStatisticsList: [],
  orderStatisticsList: [],
  tagViewStatisticsList: [],
  tagSummaryStatisticsList: [],
  total: 1,
  params: {}
}

const statistics = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TOP_CATEGORY_LIST':
      return { 
                ...state, 
                topCategoryList: action.data.topCategoryList
      }
    case 'GET_STATISTICS_LIST':
      return { ...state, 
                  connectStatisticsList: action.data.connectStatisticsList
      }
    case 'GET_ORDER_STATISTICS_LIST':
      return { ...state, 
                  orderStatisticsList: action.data.orderStatisticsList
      }
    case 'GET_TAG_VIEW_STATISTICS_LIST':
      return { ...state, 
                  tagViewStatisticsList: action.data.tagViewStatisticsList
      }
    case 'GET_TAG_SUMMARY_STATISTICS_LIST':
      return { ...state, 
                  tagSummaryStatisticsList: action.data.tagSummaryStatisticsList
      }
    default:
      return { ...state }
  }
}
export default statistics
