// ** Initial State
const initialState = {
  clientCompanyList: [],
  generalClientList: [],
  clientCompanyInfo: {},
  clientUserList: [],
  taskExcelMappingList: [],
  templateItemList: [],
  adminUserList: [],
  outpProcMthdCdList: [],
  projectList: [],
  selectedClientUser: {},
  selectedProject: {},
  duplCnt: null,
  total: 1,
  params: {}
}

const clients = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_CLIENT_COMPANY':
      return { ...state, 
        clientCompanyList: action.data.clientCompanyList,
        adminUserList : action.data.adminUserList,
        outpProcMthdCdList : action.data.outpProcMthdCdList
      }
    case 'GET_GENERAL_CLIENT_COMPANY':
      return { ...state, 
        generalClientList: action.data.clientCompanyList
      }
    case 'GET_CLIENT_COMPANY_INFO':
      return { ...state, 
        clientCompanyInfo: action.data.clientCompanyVO,
        clientUserList: action.data.clientUserList,
        projectList: action.data.projectList
      }
    case 'GET_CLIENT_PROJECT_LIST':
      return { ...state, 
        projectList: action.data.projectList
      }
    case 'GET_CLIENT_LIST':
      return { ...state,
        clientUserList: action.data.clientUserList
      }
    case 'GET_CLIENT_USER_INFO':
      return { ...state, 
        selectedClientUser: action.data.clientUserVO
      }
    case 'GET_TASK_EXCEL_MAPPING_LIST':
      return { ...state,
        taskExcelMappingList: action.data.taskExcelMappingList,
        templateItemList: action.data.templateItemList
      }
    case 'RETURN_TASK_EXCEL_MAPPING_LIST':
      return { ...state,
        taskExcelMappingList: action.data
      }
    case 'CHECK_LOGIN_ID':
      return { ...state,
        duplCnt : action.data
      }
    case 'INSERT_CLIENT':
      return {
        ...state,
        data: action.data
        // total: action.totalPages,
        // params: action.params
      }
    case 'SELECT_CLIENT_USER':
      return {
        ...state,
        selectedClientUser: action.data
      }
    case 'SELECT_PROJECT':
      return {
        ...state,
        selectedProject: action.data
      }
    case 'DOWNLOAD_FILE':
      return {
        ...state,
        downloadFile: action.data
      }
    default:
      return { ...state }
  }
}
export default clients
