// ** Initial State
const initialState = {
  categoryList: [],
  portfolioList: [],
  portfolioInfo: {},
  portfolioPhotoList: [],
  tagList: [],
  portfolioTagList: [],
  portfolioVersionList: [],
  total: 1,
  params: {}
}

const portfolios = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TAG_LIST':
      return { ...state, 
              categoryList: action.data.categoryList,
              tagList: action.data.tagList,
              portfolioVersionList: action.data.portfolioVersionList
              }
    case 'GET_PORTFOLIO_LIST':
      return { ...state, 
              portfolioList: action.data.portfolioList
              }
    case 'GET_PORTFOLIO_INFO':
      return { ...state, 
              portfolioInfo: action.data.portfolioInfo,
              portfolioTagList: action.data.portfolioTagList
              }
    case 'GET_PORTFOLIO_TAG_LIST':
      return {  
        ...state,
        portfolioTagList: action.data.portfolioTagList
        }
    case 'GET_PORTFOLIO_PHOTO_LIST':
      return { ...state, 
              portfolioPhotoList: action.data.portfolioPhotoList
              }
    case 'INSERT_TAG':
    case 'MODIFY_TAG':
    case 'DELETE_TAG':
        return {
        ...state,
        tagList: action.data
      }
    case 'INSERT_PORTFOLIO':
    case 'MODIFY_PORTFOLIO':
    case 'DELETE_PORTFOLIO':
        return {
        ...state,
        portfolioList: action.data
      }
    case 'INSERT_PORTFOLIO_TAG':
    case 'DELETE_PORTFOLIO_TAG':
        return {
          ...state,
          portfolioTagList: action.data
      }
    case 'INSERT_PORTFOLIO_PHOTO':
    case 'DELETE_PORTFOLIO_PHOTO':
        return {
        ...state,
        portfolioPhotoList: action.data
      }
    default:
      return { ...state }
  }
}
export default portfolios
