// ** Initial State
const initialState = {
  requestHistoryList: [],
  requestPhotographerList: [],
  total: 1,
  params: {}
}

const request = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_REQUEST_HISTORY_LIST':
      return {
        ...state,
        requestHistoryList: action.data.requestHistoryList
      }
    case 'GET_REQUEST_PHOTOGRAPHER_LIST':
      return { 
        ...state, 
        requestPhotographerList: action.data.requestPhotographerList
      }
    default:
      return { ...state }
  }
}
export default request
