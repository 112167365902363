// ** Initial State
const initialState = {
  clientCompanyList: [],
  photographerList: [],
  accountList: [],
  settlementFileInfo: {},
  displayType: 'unit'
}

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLIENT_COMPANIES':
      return { ...state, 
        clientCompanyList: action.data.clientCompanyList                  
      }
    case 'GET_PHOTOGRAPHERS':
      return { ...state, 
        photographerList: action.data.photographerList                  
      }
    case 'GET_ACCOUNTS':
      return { 
        ...state, 
        accountList: action.data.accountList,
        displayType: action.displayType
      }
    case 'MODIFY_TAXBILL_ISSUANCE_INFO':
      return { 
        ...state, 
        accountList: action.data.accountList
      }
    case 'GET_SETTLEMENT_EXCEL_DOWNLOAD':
      return { ...state, 
        settlementFileInfo: action.data.settlementFileInfo                  
      }
    case 'SEND_PHOTOGRAPHER_SETTLEMENT_MAIL':
      return { ...state, 
        accountList: action.data.accountList                  
      }
    default:
      return { ...state }
  }
}
export default accounts
