const initialState = {
  boardList: [],
  bbTpcdList: [],
  boardInfo: {},
  postList: []
}

const board = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BOARD_LIST': 
      return {
          ... state,
              boardList: action.data.boardList,
              bbTpcdList: action.data.bbTpcdList
      }
    case 'GET_POST_LIST': 
      return {
          ... state,
              postList: action.data.postList
      }
    default:
      return { ...state }
  }
}

export default board