// ** Initial State
const initialState = {
  spendingTaskList: [],
  spendingResolutionList: [],
  total: 1,
  params: {}
}

const spending = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SPENDING_TASK_LIST':
      return { ...state, 
        spendingTaskList: action.data.spendingTaskList
      }
    case 'GET_SPENDING_RESOLUTION_LIST':
      return { ...state, 
        spendingResolutionList: action.data.spendingResolutionList
      }
    case 'MODIFY_SPENDING_FILEUPLOAD':
      return { ...state, 
        spendingResolutionList: action.data.spendingResolutionList
      }
    case 'MODIFY_SPENDING_RESOLUTION_INFO':
      return { ...state, 
        spendingResolutionList: action.data.spendingResolutionList,
        spendingTaskList: action.data.spendingTaskList
      }
    case 'MODIFY_TASK_TAXBILL_REQUEST_INFO':
      return { ...state, 
        spendingResolutionList: action.data.spendingResolutionList,
        spendingTaskList: action.data.spendingTaskList
      }
    default:
      return { ...state }
  }
}
export default spending
