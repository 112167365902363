// ** Initial State
const initialState = {
  topCategoryList: [],
  questionList: [],
  questionItemList: [],
  questionItemAllList: [],
  itemAmountList: [],
  precedingItemList: [],
  qltyCodeList: [],
  questionInfo: {},
  questionItemInfo: {},
  total: 1,
  params: {}
}

const quotations = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TOP_CATEGORY_LIST':
      return { 
        ...state, 
        topCategoryList: action.data.topCategoryList,
        qltyCodeList: action.data.qltyCodeList
      }
    case 'GET_QUESTION_LIST':
      return { 
        ...state, 
        questionList: action.data.questionList
      }
    case 'GET_QUESTION_INFO':
      return { 
        ...state, 
        questionInfo: action.data.questionInfo,
        precedingItemList: action.data.precedingItemList
      }
    case 'GET_ITEM_AMOUNT_LIST':
      return {  
        ...state,
        itemAmountList: action.data.itemAmountList
      }
    case 'GET_PRECEDING_ITEM_LIST':
      return {  
        ...state,
        precedingItemList: action.data.precedingItemList
      }
    case 'GET_QUESTION_ITEM_LIST':
      return { 
        ...state, 
        precedingItemList: action.data.precedingItemList,
        questionItemList: action.data.questionItemList
      }
    case 'GET_QUESTION_ITEM_INFO':
      return { 
        ...state, 
        questionItemInfo: action.data
      }
    case 'GET_QUESTION_ITEM_ALL_LIST':
      return { 
        ...state, 
        questionItemAllList: action.data.questionItemAllList
      }
    case 'INSERT_QUESTION':
    case 'MODIFY_QUESTION':
    case 'DELETE_QUESTION':
      return {
        ...state,
        questionList: action.data
      }
    case 'INSERT_QUESTION_ITEM':
    case 'MODIFY_QUESTION_ITEM':
    case 'DELETE_QUESTION_ITEM':
      return {
        ...state,
        questionItemList: action.data
      }
    case 'INSERT_PRECEDING_ITEM':
    case 'DELETE_PRECEDING_ITEM':
      return {
        ...state,
        precedingItemList: action.data
      }
    default:
      return { ...state }
  }
}
export default quotations
