// ** Initial State
const initialState = {
  taskList: [],
  generalTaskList: [],
  clientCompanyList: [],
  generalClientList: [],
  clientUserList: [],
  adminUserList: [],
  projectList: [],
  phgpyUnitPriceItemList: [],
  trspfUnitPriceItemList: [],
  templateItemList: [],
  phgpyProgStcdList: [],
  phgpyCnclTpcdList: [],
  phgpyTpcdList: [],
  phgpyKndcList: [],
  cpcoDvcdList: [],
  notiTpcdList: [],
  phgpyRegnCdList: [],
  taskNotificationList: [],
  taskReplyList: [],
  taskCheckList: [],
  searchPhotographerList: [],
  uploadFailList : [],
  cooperationCompanyList: [],
  taskParticipationList: [],
  taskParticipationScheduleList: [],
  taskInfo: {},
  generalTaskInfo: {},
  sendAlimtalkResult: '',
  alimtalkTargetCount: '',
  alimtalkSuccessCount: '',
  sendEmailResult: '',
  emailTargetCount: '',
  emailSuccessCount: '',
  total: 1,
  params: {}
}

const task = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLCO_LIST':
      return { ...state, 
                  adminUserList: action.data.adminUserList,
                  clientCompanyList: action.data.clientCompanyList
              }
    case 'GET_GENERAL_CLIENT_LIST':
      return { ...state, 
                  generalClientList: action.data.clientCompanyList
              }
    case 'GET_PROJECT_LIST':
      return { ...state, 
                  projectList: action.data.projectList
              }
    case 'GET_PLATFORM_TASK_LIST':
      return { ...state, 
                  taskList: action.data.taskList
              }
    case 'GET_PLATFORM_TASK_INFO':
      return { ...state, 
                  taskInfo: action.data.taskVO,
                  clientUserList: action.data.clientUserList,
                  // adminUserList: action.data.adminUserList,
                  phgpyUnitPriceItemList: action.data.phgpyUnitPriceItemList,
                  trspfUnitPriceItemList: action.data.trspfUnitPriceItemList,
                  templateItemList: action.data.templateItemList,
                  phgpyProgStcdList: action.data.phgpyProgStcdList,
                  phgpyCnclTpcdList: action.data.phgpyCnclTpcdList,
                  phgpyTpcdList: action.data.phgpyTpcdList,
                  notiTpcdList: action.data.notiTpcdList,
                  phgpyRegnCdList: action.data.phgpyRegnCdList,
                  taskReplyList: action.data.taskReplyList,
                  taskNotificationList: action.data.taskNotificationList
              }
    case 'GET_GENERAL_TASK_LIST':
      return { ...state, 
                  generalTaskList: action.data.generalTaskList
              }
    case 'GET_COOPERATION_COMPANY_LIST':
      return { ...state, 
                  cooperationCompanyList: action.data.cooperationCompanyList
              }
    case 'GET_GENERAL_TASK_INFO':
      return { ...state, 
                  generalTaskInfo: action.data.generalTaskVO,
                  phgpyProgStcdList: action.data.phgpyProgStcdList,
                  phgpyCnclTpcdList: action.data.phgpyCnclTpcdList,
                  phgpyTpcdList: action.data.phgpyTpcdList,
                  cpcoDvcdList: action.data.cpcoDvcdList,
                  phgpyKndcList: action.data.phgpyKndcList,
                  notiTpcdList: action.data.notiTpcdList,
                  phgpyRegnCdList: action.data.phgpyRegnCdList,
                  taskReplyList: action.data.taskReplyList,
                  taskNotificationList: action.data.taskNotificationList,
                  taskParticipationList: action.data.taskParticipationList
              }
    case 'INSERT_TASK':
      return {
        ...state,
        data: action.data
      }
    case 'INSERT_GENERAL_TASK':
      return { ...state, 
                  generalTaskList: action.data.generalTaskList
      }
    case 'INSERT_TASK_PARTICIPATION':
    case 'MODIFY_TASK_PARTICIPATION':
    case 'DELETE_TASK_PARTICIPATION':
      return { ...state, 
                  taskParticipationList: action.data.taskParticipationList,
                  generalTaskInfo: action.data.generalTaskVO
      }
    case 'GET_TASK_PARTICIPATION_SCHEDULE_LIST':
      return { ...state, 
                  taskParticipationScheduleList: action.data.taskParticipationScheduleList
      }
    case 'INSERT_TASK_PARTICIPATION_SCHEDULE':
    case 'MODIFY_TASK_PARTICIPATION_SCHEDULE':
    case 'DELETE_TASK_PARTICIPATION_SCHEDULE':
      return { ...state, 
                  taskParticipationScheduleList: action.data
      }
                
    case 'GET_TASK_REPLY_LIST':
      return { ...state, 
                  taskReplyList: action.data.taskReplyList
              }
    case 'GET_TASK_CHECKLIST':
      return { ...state, 
                  taskCheckList: action.data.taskCheckList
              }
    case 'GET_SEARCH_PHOTOGRAPHER':
      return { ...state, 
                  searchPhotographerList: action.data.searchPhotographerList
              }
    case 'INSERT_TASK_EXCEL_UPLOAD':
      return { ...state, 
                  uploadFailList: action.data.uploadFailList
              }
    case 'SEND_DATE_FIX_ALIMTALK':
    case 'SEND_OUTPUT_UPLOAD_ALIMTALK':
      return { ...state, 
                  sendAlimtalkResult: action.data.sendResult,
                  alimtalkTargetCount: action.data.alimtalkTargetCount,
                  alimtalkSuccessCount: action.data.alimtalkSuccessCount,
                  taskVO: action.data.taskVO
              }
    case 'SEND_OUTPUT_UPLOAD_CLIENT_EMAIL':
      return { ...state, 
                  sendEmailResult: action.data.sendResult,
                  emailTargetCount: action.data.emailTargetCount,
                  emailSuccessCount: action.data.emailSuccessCount,
                  taskVO: action.data.taskVO
              }          
    default:
      return { ...state }
  }
}
export default task
