// ** Initial State
const initialState = {
  shootingStatusList: [],
  shootingCompleteList: [],
  shootingRegionMapList: [],
  photographerShootingStatusList: [],
  clientShootingStatusList: [],
  clientsMonthlyRevenueList: [],
  params: {}
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SHOOTING_STATUS':
      return { ...state, 
        shootingStatusList: action.data.shootingStatusList                  
      }
    case 'GET_SHOOTING_COMPLETE':
      return { ...state, 
        shootingCompleteList: action.data.shootingCompleteList                  
      }
    case 'GET_SHOOTING_REGION_MAP':
      return { ...state, 
        shootingRegionMapList: action.data.shootingRegionMapList                  
      }
    case 'GET_PHOTOGRAPHER_SHOOTING_STATUS':
      return { ...state, 
        photographerShootingStatusList: action.data.photographerShootingStatusList                  
      }
    case 'GET_CLIENT_SHOOTING_STATUS':
      return { ...state, 
        clientShootingStatusList: action.data.clientShootingStatusList                  
      }
    case 'GET_CLIENT_MONTHLY_REVENUE':
      return { ...state, 
        clientsMonthlyRevenueList: action.data.clientsMonthlyRevenueList                  
      }  
    default:
      return { ...state }
  }
}
export default dashboard
