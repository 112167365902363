// **  Initial State
const initialState = {
  accessToken : ''
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        accessToken: action.accessToken
        // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    // case 'LOGOUT':
    //   const obj = { ...action }
    //   delete obj.type
    //   return { ...state, userData: {}, ...obj }
    default:
      return state
  }
}

export default authReducer
