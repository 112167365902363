// ** Initial State
const initialState = {
  ordProcStcdList: [],
  orderList: [],
  orderInfo: {},
  orderProductList: [],
  orderProductOptionList: [],
  total: 1,
  params: {}
}

const orderProduct = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CODE_LIST':
      return {
        ...state,
        ordProcStcdList: action.data.ordProcStcdList
      }
    case 'GET_ORDER_LIST':
      return {
        ...state,
        orderList: action.data.orderList
      }
    case 'GET_ORDER_PRODUCT_LIST':
      return { ...state, 
                  orderInfo: action.data.orderInfo,
                  orderProductList: action.data.orderProductList,
                  orderProductOptionList: action.data.orderProductOptionList
              }
    default:
      return { ...state }
  }
}
export default orderProduct
