// ** Initial State
const initialState = {
  photographerList: [],
  phgrpTaskList: [],
  phgrpOffDayList: [],
  categoryList: [],
  regnCdList: [],
  photographerCategoryList: [],
  photographerRegionList: [],
  sklGrdDvcdList: [],
  cprtTndcDvcdList: [],
  codeList: [],
  bankCdList: [],
  rcrtChnlDvcdList: [],
  bizTpcdList: [],
  phgrpMgntStcdList: [],
  duplCnt: null,
  total: 1,
  params: {},
  selectedPhotographer: {}
}

const photographers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, 
                  photographerList: action.data.photographerList,
                  categoryList: action.data.categoryList,
                  regnCdList: action.data.regnCdList
      }
    case 'GET_DATA':
      return { ...state,
                  categoryList: action.data.categoryList,
                  regnCdList: action.data.regnCdList,
                  codeList: action.data.codeList,
                  sklGrdDvcdList: action.data.sklGrdDvcdList,
                  cprtTndcDvcdList: action.data.cprtTndcDvcdList,
                  bankCdList: action.data.bankCdList,
                  rcrtChnlDvcdList: action.data.rcrtChnlDvcdList,
                  bizTpcdList: action.data.bizTpcdList,
                  phgrpMgntStcdList: action.data.phgrpMgntStcdList
      }
    case 'GET_PHOTOGRAPHER':
      return { ...state, 
                  selectedPhotographer: action.data.photographerVO,
                  photographerCategoryList: action.data.photographerCategoryList,
                  photographerRegionList: action.data.photographerRegionList,
                  categoryList: action.data.categoryList,
                  regnCdList: action.data.regnCdList,
                  sklGrdDvcdList: action.data.sklGrdDvcdList,
                  cprtTndcDvcdList: action.data.cprtTndcDvcdList,
                  bankCdList: action.data.bankCdList,
                  rcrtChnlDvcdList: action.data.rcrtChnlDvcdList,
                  bizTpcdList: action.data.bizTpcdList,
                  phgrpMgntStcdList: action.data.phgrpMgntStcdList
              }
    case 'GET_PHOTOGRAPHER_CALENDAR_TASK':
      return { ...state,
                  phgrpTaskList: action.data.taskList,
                  phgrpOffDayList: action.data.photographerOffDayList
      }
    case 'GET_REGION':
      return { ...state, 
                  photographerRegionList: action.data.photographerRegionList
              }
    case 'GET_CATEGORY':
      return { ...state, 
                  photographerCategoryList: action.data.photographerCategoryList
              }
    case 'CHECK_LOGIN_ID':
      return { ...state,
                  duplCnt : action.data
      }
    case 'INSERT_COOPERATION_COMPANY':
      return { ...state,
                  selectedPhotographer: action.data.photographerVO
      }
    default:
      return { ...state }
  }
}
export default photographers
