// ** Initial State
const initialState = {
  templateList: [],
  templateItemList: [],
  categoryList: [],
  clientCompanyList: [],
  templateItemType: [],
  selectedTemplateItem: {},
  total: 1,
  params: {}
}

const template = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TEMPLATELIST':
      return { ...state, 
                  templateList: action.data.templateList,
                  categoryList: action.data.categoryList,
                  clientCompanyList: action.data.clientCompanyList,
                  templateItemType: action.data.templateItemType,
                  templateItemList: []
              }
    case 'GET_TEMPLATEITEMLIST':
      return { ...state, 
                  templateItemList: action.data.templateItemList
              }
    case 'INSERT_TEMPLATE':
      return {
        ...state,
        data: action.data
        // total: action.totalPages,
        // params: action.params
      }
    case 'SELECT_ITEM_DATA':
      return {
        ...state,
        selectedTemplateItem: action.data
      }
    default:
      return { ...state }
  }
}
export default template
