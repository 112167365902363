// ** Initial State
const initialState = {
  clientCompanyList: [],
  selectedCompany: {},
  projectList: [],  
  notificationList: [],
  unreadCount: 0,
  total: 1,
  params: {}
}

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLIENT_COMPANIES':
      return { ...state, 
        clientCompanyList: action.data.clientCompanyList                  
      }
    case 'SET_SELECTED_COMPANY':
      return { ...state, 
        selectedCompany: action.data                  
      }    
    case 'GET_CLIENT_PROJECT':
      return { ...state,
        projectList: action.data.projectList
      }
    case 'GET_ALL_NOTIFICATION':
      return { ...state, 
        notificationList: action.data.notificationList,
        unreadCount: action.data.unreadCount                 
      }      
    default:
      return { ...state }
  }
}
export default notifications
