// ** Initial State
const initialState = {
  events: [],
  clientCompanyList: [],
  selectedCompany: {},
  selectedProject: {},
  checkList:[],
  categoryList:[],
  templateList:[],
  unitPriceList:[],
  projectList: [],
  sendTemplateList: [],
  taskList: [],
  generalTaskList: [],
  uploadFailList : [],
  selectedEvent: {},
  selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
}

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLIENT_COMPANIES':
      return { ...state, 
        clientCompanyList: action.data.clientCompanyList                  
      }
    case 'SET_SELECTED_COMPANY':
      return { ...state, 
        selectedCompany: action.data                  
      }
    case 'GET_CLIENT_PROJECT':
      return { ...state,
        projectList: action.data.projectList
      }
    case 'SELECT_PROJECT':
      return { ...state,
        selectedProject: action.data
      }
    case 'ADD_PROJECT':
      return { ...state,
        checkList: action.data.checkList,
        categoryList: action.data.categoryList,
        templateList: action.data.templateList,
        unitPriceList: action.data.unitPriceList,
        clientCompanyList: action.data.clientCompanyList,
        sendTemplateList: action.data.sendTemplateList
      }
    case 'GET_PROJECT_TASK':
      return { ...state,
        taskList: action.data.taskList
      }
    case 'INSERT_GENERAL_TASK':
    case 'MODIFY_GENERAL_TASK':
    case 'GET_GENERAL_TASK_LIST':
      return { ...state,
        generalTaskList: action.data.generalTaskList
      }
    case 'INSERT_PLATFORM_TASK_EXCEL_UPLOAD':
      return { ...state, 
        uploadFailList: action.data.uploadFailList
      }
    case 'ON_CHANGE_PROJECT_SLIDE':
      return { ...state,
        selectedCompany: state.clientCompanyList[action.data]
      }
    case 'FETCH_EVENTS':
      return { ...state, events: action.events }
    case 'ADD_EVENT':
      return { ...state }
    case 'REMOVE_EVENT':
      return { ...state }
    case 'UPDATE_EVENT':
      return { ...state }
    case 'UPDATE_FILTERS':
      // ** Updates Filters based on action filter
      const filterIndex = state.selectedCalendars.findIndex(i => i === action.filter)
      if (state.selectedCalendars.includes(action.filter)) {
        state.selectedCalendars.splice(filterIndex, 1)
      } else {
        state.selectedCalendars.push(action.filter)
      }
      if (state.selectedCalendars.length === 0) {
        state.events.length = 0
      }
      return { ...state }
    case 'UPDATE_ALL_FILTERS':
      // ** Updates All Filters based on action value
      const value = action.value
      let selected = []
      if (value === true) {
        selected = ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
      } else {
        selected = []
      }
      return { ...state, selectedCalendars: selected }
    case 'SELECT_EVENT':
      return { ...state, selectedEvent: action.event }
    default:
      return state
  }
}

export default projectReducer
