// ** Initial State
const initialState = {
  cooperationCompanyList: [],
  cpcoDvcdList: [],
  bizTpcdList: [],
  bankCdList: [],
  total: 1,
  params: {}
}

const cooperations = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_COOPERATION_COMPANY':
      return { ...state, 
        cooperationCompanyList: action.data.cooperationCompanyList,
        cpcoDvcdList : action.data.cpcoDvcdList,
        bizTpcdList : action.data.bizTpcdList,
        bankCdList : action.data.bankCdList
      }
    case 'INSERT_COOPERATION_COMPANY':
    case 'MODIFY_COOPERATION_COMPANY':
    case 'DELETE_COOPERATION_COMPANY':
      return { ...state, 
        cooperationCompanyList: action.data
      }
    default:
      return { ...state }
  }
}
export default cooperations
